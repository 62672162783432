//储存每个页面的操作按钮及对应的权限code
import {OPERATIONS} from "@/components/table/tableOperations";

export default {
    lineManager: {
        'ADD': 'cw-manage-teamBuilding-line-add',
        'CHECK': 'cw-manage-teamBuilding-line-get',
        'MODIFY': 'cw-manage-teamBuilding-line-update',
        'DELETE': 'cw-manage-teamBuilding-line-del',
        'switch': 'cw-manage-teamBuilding-line-updown',
    },
    introduceInfo: {
        'MODIFY': 'cw-manage-teamBuilding-introduce-update',
    },
    lineSharing: {
        'ADD': 'cw-manage-teamBuilding-lineSharing-add',
        'CHECK': 'cw-manage-teamBuilding-lineSharing-get',
        'MODIFY': 'cw-manage-teamBuilding-lineSharing-update',
        'DELETE': 'cw-manage-teamBuilding-lineSharing-del',
    },
    partnershipInfo: {
        'MODIFY': 'cw-manage-teamBuilding-partnership-update',
    },
    lineAreaConfig: {
        'ADD': 'cw-manage-teamBuilding-config-area-add',
        'MODIFY': 'cw-manage-teamBuilding-config-area-update',
        'DELETE': 'cw-manage-teamBuilding-config-area-del',
        'ADDNEXT': 'cw-manage-teamBuilding-config-area-add',
    },
    lineTypeConfig: {
        'ADD': 'cw-manage-teamBuilding-config-type-add',
        'MODIFY': 'cw-manage-teamBuilding-config-type-update',
        'DELETE': 'cw-manage-teamBuilding-config-type-del',
    },
    albumManager: {
        'ADD': 'cw-manage-memory-albumManage-add',
        'MODIFY': 'cw-manage-memory-albumManage-update',
        'DELETE': 'cw-manage-memory-albumManage-del'
    },
    hotelinput: {
        'ADD': 'cw-manage-hotel-manage-add-add',
        'MODIFY': 'cw-manage-hotel-manage-add-update',
        'DELETE': 'cw-manage-hotel-manage-add-del',
        'CHECK': 'cw-manage-hotel-manage-add-get',
        'EXPORT': 'cw-manage-hotel-manage-add-export',
        'CATEGORYADD': 'cw-manage-hotel-manage-add-category-add',
        'CATEGORYMODIFY': 'cw-manage-hotel-manage-add-category-update',
        'CATEGORYDELETE': 'cw-manage-hotel-manage-add-category-del',
    },
    hotelmanager: {
        'ADD': 'cw-manage-hotel-manage-list-add',
        'MODIFY': 'cw-manage-hotel-manage-list-update',
        'DELETE': 'cw-manage-hotel-manage-list-del',
        'CHECK': 'cw-manage-hotel-manage-list-get',
        'EXPORT': 'cw-manage-hotel-manage-list-export',
        'appletsCode': 'cw-manage-hotel-manage-list-code',
        'switch': 'cw-manage-hotel-manage-list-updown',
        'CATEGORYADD': 'cw-manage-hotel-manage-list-category-add',
        'CATEGORYMODIFY': 'cw-manage-hotel-manage-list-category-update',
        'CATEGORYDELETE': 'cw-manage-hotel-manage-list-category-del',
    },
    hotelstockmanager: {
        'inventory': 'cw-manage-hotel-stock-control-get'
    },
    hotelfacilitiemanager: {
        'ADD': 'cw-manage-hotel-config-plan-add',
        'MODIFY': 'cw-manage-hotel-config-plan-update',
        'DELETE': 'cw-manage-hotel-config-plan-del'
    },
    roomfacilitiemanager: {
        'ADD': 'cw-manage-hotel-config-rooms-add',
        'MODIFY': 'cw-manage-hotel-config-rooms-update',
        'DELETE': 'cw-manage-hotel-config-rooms-del'
    },
    servicemanager: {
        'ADD': 'cw-manage-hotel-config-cateringservice-add',
        'MODIFY': 'cw-manage-hotel-config-cateringservice-update',
        'DELETE': 'cw-manage-hotel-config-cateringservice-del'
    },
    hotellabelmanager: {
        'ADD': 'cw-manage-hotel-config-label-add',
        'MODIFY': 'cw-manage-hotel-config-label-update',
        'DELETE': 'cw-manage-hotel-config-label-del'
    },
    travelinput: {
        'ADD': 'cw-manage-travel-manage-add-add',
        'MODIFY': 'cw-manage-travel-manage-add-update',
        'DELETE': 'cw-manage-travel-manage-add-del',
        'CHECK': 'cw-manage-travel-manage-add-get',
        'EXPORT': 'cw-manage-travel-manage-add-export',
    },
    travelmanager: {
        'MODIFY': 'cw-manage-travel-manage-list-update',
        'DELETE': 'cw-manage-travel-manage-list-del',
        'CHECK': 'cw-manage-travel-manage-list-get',
        'EXPORT': 'cw-manage-travel-manage-list-export',
        'appletsCode': 'cw-manage-travel-manage-list-code',
        'switch': 'cw-manage-travel-manage-list-updown',
    },
    groupremainmanager: {
        'viewDetails': 'cw-manage-travel-manage-group-remainSeat-get',
    },
    travellabelmanager: {
        'ADD': 'cw-manage-travel-config-productlabel-add',
        'MODIFY': 'cw-manage-travel-config-productlabel-update',
        'DELETE': 'cw-manage-travel-config-productlabel-del',
        'ENABLE': 'cw-manage-travel-config-productlabel-enable',
    },
    groupmanager: {
        'ADD': 'cw-manage-travel-config-joinnature-add',
        'MODIFY': 'cw-manage-travel-config-joinnature-update',
        'DELETE': 'cw-manage-travel-config-joinnature-del',
        'ENABLE': 'cw-manage-travel-config-joinnature-enable',
    },
    additionalfeesmanager: {
        'ADD': 'cw-manage-travel-config-additional-fees-add',
        'MODIFY': 'cw-manage-travel-config-additional-fees-update',
        'DELETE': 'cw-manage-travel-config-additional-fees-del',
    },
    travelclassifymanager: {
        'ADD': 'cw-manage-travel-config-classify-add',
        'MODIFY': 'cw-manage-travel-config-classify-update',
        'DELETE': 'cw-manage-travel-config-classify-del',
    },
    TravelAreaManager: {
        'ADD': 'cw-manage-travel-config-area-add',
        'MODIFY': 'cw-manage-travel-config-area-update',
        'DELETE': 'cw-manage-travel-config-area-del',
        'ADDNEXT': 'cw-manage-travel-config-area-addnext',
    },
    goodsinput: {
        'ADD': 'cw-manage-goods-manage-add-add',
        'MODIFY': 'cw-manage-goods-manage-add-update',
        'DELETE': 'cw-manage-goods-manage-add-del',
        'CHECK': 'cw-manage-goods-manage-add-get',
        'EXPORT': 'cw-manage-goods-manage-add-export',
        'examine': 'cw-manage-goods-manage-add-examine',
        'CATEGORYADD': 'cw-manage-goods-manage-add-categroy-add',
        'CATEGORYMODIFY': 'cw-manage-goods-manage-add-categroy-update',
        'CATEGORYDELETE': 'cw-manage-goods-manage-add-categroy-del',
    },
    goodsmanager: {
        'MODIFY': 'cw-manage-goods-manage-list-update',
        'DELETE': 'cw-manage-goods-manage-list-del',
        'CHECK': 'cw-manage-goods-manage-list-get',
        'EXPORT': 'cw-manage-goods-manage-list-export',
        'appletsCode': 'cw-manage-goods-manage-list-code',
        'switch': 'cw-manage-goods-manage-list-updown',
        'CATEGORYADD': 'cw-manage-goods-manage-list-category-add',
        'CATEGORYMODIFY': 'cw-manage-goods-manage-list-category-update',
        'CATEGORYDELETE': 'cw-manage-goods-manage-list-category-del',
    },
    stockmanager: {
        'inventory': 'cw-manage-goods-manage-stocklist-get',
        'EXPORT': 'cw-manage-goods-manage-stocklist-export',
    },
    stockrecode: {
        'IMPORT': 'cw-manage-goods-manage-stockchangelist-import',
        'EXPORT': 'cw-manage-goods-manage-stockchangelist-export',
        'orderInfo': 'cw-manage-goods-manage-stockchangelist-orderinfo',
    },
    specsmanager: {
        'ADD': 'cw-manage-goods-config-specs-add',
        'MODIFY': 'cw-manage-goods-config-specs-update',
        'DELETE': 'cw-manage-goods-config-specs-del',
    },
    goodslabelmanager: {
        'ADD': 'cw-manage-goods-config-label-add',
        'MODIFY': 'cw-manage-goods-config-label-update',
        'DELETE': 'cw-manage-goods-config-label-del',
    },
    expressmanager: {
        'ADD': 'cw-manage-goods-config-postagetemplate-add',
        'MODIFY': 'cw-manage-goods-config-postagetemplate-update',
        'DELETE': 'cw-manage-goods-config-postagetemplate-del',
        'CHECK': 'cw-manage-goods-config-postagetemplate-get',
    },
    takegoodsmanager: {
        'ADD': 'cw-manage-goods-config-sep-add',
        'MODIFY': 'cw-manage-goods-config-sep-update',
        'DELETE': 'cw-manage-goods-config-sep-del',
        'CHECK': 'cw-manage-goods-config-sep-get',
    },
    goodsorder: {
        'PICKUP': 'cw-manage-order-product-pickup',
        'shipments': 'cw-manage-order-product-send',
        'CHECK': 'cw-manage-order-product-get',
        'cancelOrder': 'cw-manage-order-product-cancel',
        'EXPORT': 'cw-manage-order-product-export',
    },
    travelorder: {
        'USED': 'cw-manage-order-travel-member-used',
        'shipments': 'cw-manage-order-travel-member-send',
        'CHECK': 'cw-manage-order-travel-member-get',
        'cancelOrder': 'cw-manage-order-travel-member-cancel',
        'acceptOrder': 'cw-manage-order-travel-member-confirmed',
        'returned': 'cw-manage-order-travel-member-complete',
        'EXPORT': 'cw-manage-order-travel-member-export',
    },
    hotelorder: {
        'USED': 'cw-manage-order-travel-hotel-used',
        'CHECK': 'cw-manage-order-travel-hotel-get',
        'cancelOrder': 'cw-manage-order-travel-hotel-cancel',
        'acceptOrder': 'cw-manage-order-travel-hotel-confirmed',
        'acceptHotelOrder': 'cw-manage-order-travel-hotel-checkin',
        'EXPORT': 'cw-manage-order-travel-hotel-export',
    },
    aftersaleorder: {
        'USED': 'cw-manage-order-travel-hotel-used',
        'CHECK': 'cw-manage-order-travel-hotel-get',
        'cancelOrder': 'cw-manage-order-travel-hotel-cancel',
        'acceptOrder': 'cw-manage-order-travel-hotel-confirmed',
        'acceptHotelOrder': 'cw-manage-order-travel-hotel-checkin',
        'EXPORT': 'cw-manage-order-travel-hotel-export',
        'giveCoupon': 'cw-manage-order-after-sale-coupon',
        "select": "cw-manage-order-after-sale-coupon",
        "del": "cw-manage-order-after-sale-coupon"

    },
    lineorder: {
        'docking': 'cw-manage-order-travel-line-abutment',
        'cancelOrder': 'cw-manage-order-travel-line-cancel',
        'confirmOrder': 'cw-manage-order-travel-line-confirm',
    },
    plusorder: {
        'EXPORT': 'cw-manage-order-plus-export',
    },
    promotionsmanager: {
        'ADD': 'cw-manage-market-activity-list-add',
        'ADD2': 'cw-manage-market-activity-list-add2',
        'CHECK': 'cw-manage-market-activity-list-get',
        'DELETE': 'cw-manage-market-activity-list-del',
        'MODIFY': 'cw-manage-market-activity-list-update',
        'EXPORT': 'cw-manage-market-activity-list-export',
        'cancelPublish': 'cw-manage-market-activity-list-status-change',
        'publish': 'cw-manage-market-activity-list-status-change',
        'COPY': 'cw-manage-market-activity-list-clone',
    },
    giftmanager: {
        'ADD': 'cw-manage-market-activity-giftbag-add',
        'CHECK': 'cw-manage-market-activity-giftbag-get',
        'DELETE': 'cw-manage-market-activity-giftbag-del',
        'MODIFY': 'cw-manage-market-activity-giftbag-update',
        'EXPORT': 'cw-manage-market-activity-giftbag-export',
        'giftCode': 'cw-manage-market-activity-giftbag-giftcode',
        'appletsCode': 'cw-manage-market-activity-giftbag-code',
        'homeShow': 'cw-manage-market-activity-giftbag-index',
        'switch': 'cw-manage-market-activity-giftbag-updown',
    },
    travelinput2: {
        'ADD': 'cw-manage-market-manage-add2-add',
        'CHECK': 'cw-manage-market-manage-add2-get',
        'EXPORT': 'cw-manage-market-manage-add2-export',
    },
    travelmanager2: {
        'CHECK': 'cw-manage-market-manage-list2-get',
        'MODIFY': 'cw-manage-market-manage-list2-update',
        'DELETE': 'cw-manage-market-manage-list2-del',
        'appletsCode': 'cw-manage-market-manage-list2-code',
        'EXPORT': 'cw-manage-market-manage-list2-export',
        'switch': 'cw-manage-market-manage-list2-updown',
    },
    couponmanager: {
        'ADD': 'cw-manage-market-coupon-list-add',
        'MODIFY': 'cw-manage-market-coupon-list-update',
        'CHECK': 'cw-manage-market-coupon-list-get',
        'grant': 'cw-manage-market-coupon-list-send',
        'couponStop': 'cw-manage-market-coupon-list-stop',
        'couponStart': 'cw-manage-market-coupon-list-start',
        'grantRecord': 'cw-manage-market-coupon-list-sendlist',
        'EXPORT': 'cw-manage-market-coupon-list-export',
        'revoke': 'cw-manage-market-coupon-list-revoke',
        'userStop': 'cw-manage-market-coupon-list-userstop',

    },
    couponTimingManager: {
        'ADD': 'cw-manage-market-time-send-add',
        'CHECK': 'cw-manage-market-time-send-get',
        'COPY': 'cw-manage-market-time-send-copy',
        'MODIFY': 'cw-manage-market-time-send-update',
        'couponStop': 'cw-manage-market-time-send-start',
        'couponStart': 'cw-manage-market-time-send-start',
        'EXPORT': 'cw-manage-market-coupon-list-export',
    },
    bannerconfigure: {
        'ADD': 'cw-manage-market-recommend-homebanner-add',
        'MODIFY': 'cw-manage-market-recommend-homebanner-update',
        'DELETE': 'cw-manage-market-recommend-homebanner-del',
        'MOVE': 'cw-manage-market-recommend-homebanner-move',
    },
    recommendconfigure: {
        'ADDPRODUCT': 'cw-manage-market-recommend-hot-addproducut',
        'ADDTRAVEL': 'cw-manage-market-recommend-hot-addtravel',
        'ADDHOTEL': 'cw-manage-market-recommend-hot-addhotel',
        'MODIFY': 'cw-manage-market-recommend-hot-update',
        'DELETE': 'cw-manage-market-recommend-hot-del',
        'MOVE': 'cw-manage-market-recommend-hot-move',
    },
    messagemanage: {
        'ADD': 'cw-manage-market-message-list-add',
        'CHECK': 'cw-manage-market-message-list-get',
        'MODIFY': 'cw-manage-market-message-list-update',
        'DELETE': 'cw-manage-market-message-list-del',
        'PSUH': 'cw-manage-market-message-list-send',
        'EXPORT': 'cw-manage-market-message-list-export',
    },
    transactiondetail: {
        'CHECK': 'cw-manage-finance-transactiondetails-get',
        'EXPORT': 'cw-manage-finance-transactiondetails-export',
    },
    depositmanager: {
        'ADD': 'cw-manage-finance-stored-value-add',
        'ADDS': 'cw-manage-finance-stored-value-adds',
        'CARDS': 'cw-manage-finance-stored-value-cards',
        'LIST': 'cw-manage-finance-stored-value-list',
        'FREEZE': 'cw-manage-finance-stored-value-freeze',
        'CHECK': 'cw-manage-finance-stored-value-get',
        'EXPORT': 'cw-manage-finance-stored-value-export',
        'DELETED': 'cw-manage-finance-stored-value-del',
    },
    financestockmanager: {
        'checkInfo': 'cw-manage-finance-stock-control-get',
        'EXPORT': 'cw-manage-finance-stock-control-export',
    },
    cashiermanager: {
        'CHECK': 'cw-manage-finance-cashier-control-get',
        'EXPORT': 'cw-manage-finance-cashier-control-export',
        'switch': 'cw-manage-finance-cashier-control-invoicing',
    },
    invoicemanager: {},
    contractmanager: {
        'ADD': 'cw-manage-finance-cashier-contract-add',
        'DELETE': 'cw-manage-finance-cashier-contract-del',
        'MODIFY': 'cw-manage-finance-cashier-contract-update',
        'CHECK': 'cw-manage-finance-cashier-contract-get',
        'up': 'cw-manage-finance-cashier-contract-up',
        'down': 'cw-manage-finance-cashier-contract-down',
        'EXPORT': 'cw-manage-finance-cashier-contract-export',
    },
    contractlabelmanager: {
        'ADD': 'cw-manage-finance-cashier-contract-type-add',
        'MODIFY': 'cw-manage-finance-cashier-contract-type-update',
        'DELETE': 'cw-manage-finance-cashier-contract-type-del',
    },
    basemanager2: {
        'CHECK': 'cw-manage-finance-shop-manage-get',
    },
    pointsmanager: {
        'ADD': 'cw-manage-finance-integral-add',
        'ADDS': 'cw-manage-finance-integral-adds',
        'CHECK': 'cw-manage-finance-integral-get',
        'DELETE': 'cw-manage-finance-integral-del',
        'EXPORT': 'cw-manage-finance-integral-export',
        'DELETED': 'cw-manage-finance-integral-del',
        'INSTALL': 'cw-manage-finance-integral-install',
        'MODIFY': 'cw-manage-finance-integral-install-update'
    },
    integralManager: {
        'MODIFY': 'cw-manage-finance-integral-install-update'
    },
    integralProduct: {
        'ADD': "cw-manage-finance-integral-product-add",
        'CHECK': "cw-manage-finance-integral-product-get",
        'MODIFY': "cw-manage-finance-integral-product-update",
        'DELETE': "cw-manage-finance-integral-product-del",
        'EXPORT': 'cw-manage-finance-integral-product-export',
        'appletsCode': 'cw-manage-finance-integral-product-code',
        'switch': 'cw-manage-finance-integral-product-updown',
    },
    integralHotel: {
        'ADD': "cw-manage-finance-integral-hotel-add",
        'CHECK': "cw-manage-finance-integral-hotel-get",
        'MODIFY': "cw-manage-finance-integral-hotel-update",
        'DELETE': "cw-manage-finance-integral-hotel-del",
        'EXPORT': 'cw-manage-finance-integral-hotel-export',
        'appletsCode': 'cw-manage-finance-integral-hotel-code',
        'switch': 'cw-manage-finance-integral-hotel-updown',
    },
    integralTravel: {
        'ADD': "cw-manage-finance-integral-travel-add",
        'CHECK': "cw-manage-finance-integral-travel-get",
        'MODIFY': "cw-manage-finance-integral-travel-update",
        'DELETE': "cw-manage-finance-integral-travel-del",
        'EXPORT': 'cw-manage-finance-integral-travel-export',
        'appletsCode': 'cw-manage-finance-integral-travel-code',
        'switch': 'cw-manage-finance-integral-travel-updown',
    },
    goodsexamine: {
        'pass': 'cw-manage-approval-product-pass',
        'refusal': 'cw-manage-approval-product-reject',
        'changeShop': 'cw-manage-approval-product-changeshop',
        'MODIFY': 'cw-manage-approval-product-update',
        'CHECK': 'cw-manage-approval-product-get',
        'CATEGORYADD': 'cw-manage-approval-product-addcategory',
        'CATEGORYMODIFY': 'cw-manage-approval-product-updatecategroy',
        'CATEGORYDELETE': 'cw-manage-approval-product-delcategorp',
    },
    travelexamine: {
        'pass': 'cw-manage-approval-travel-pass',
        'refusal': 'cw-manage-approval-travel-reject',
        'changeShop': 'cw-manage-approval-travel-changeshop',
        'MODIFY': 'cw-manage-approval-travel-update',
        'CHECK': 'cw-manage-approval-travel-get',
        'CATEGORYADD': 'cw-manage-approval-travel-addcategory',
        'CATEGORYMODIFY': 'cw-manage-approval-travel-updatecategroy',
        'CATEGORYDELETE': 'cw-manage-approval-travel-delcategorp',
    },
    hotelexamine: {
        'pass': 'cw-manage-approval-hotel-pass',
        'refusal': 'cw-manage-approval-hotel-reject',
        'changeShop': 'cw-manage-approval-hotel-changeshop',
        'MODIFY': 'cw-manage-approval-hotel-update',
        'CHECK': 'cw-manage-approval-hotel-get',
        'CATEGORYADD': 'cw-manage-approval-hotel-addcategory',
        'CATEGORYMODIFY': 'cw-manage-approval-hotel-updatecategroy',
        'CATEGORYDELETE': 'cw-manage-approval-hotel-delcategorp',
    },
    depositexamine: {
        'CHECK': "cw-manage-approval-deposit-get",
        'pass': "cw-manage-approval-deposit-pass",
        'refusal': "cw-manage-approval-deposit-reject"
    },
    pointsexamine: {
        'CHECK': "cw-manage-approval-rewardpoints-get",
        'pass': "cw-manage-approval-rewardpoints-pass",
        'refusal': "cw-manage-approval-rewardpoints-reject"
    },
    lineexamine: {
        'CHECK': "cw-manage-approval-line-get",
        'pass': "cw-manage-approval-line-pass",
        'refusal': "cw-manage-approval-line-reject",
        'MODIFY': "cw-manage-approval-line-update"
    },
    membermanager: {
        'CHECK': "cw-manage-member-list-get",
        'orderRecord': "cw-manage-member-list-order",
        'convertDistr': "cw-manage-member-list-change",
        'switch': "cw-manage-member-list-updown",
        'EXPORT': "cw-manage-member-list-export",
        'memberLevelChange': 'cw-manage-member-list-c-l'
    },
    distributormanager: {
        'CHECK': "cw-manage-member-distributorshiplist-get",
        'unbindDistr': "cw-manage-member-distributorshiplist-del",
        'switch': "cw-manage-member-distributorshiplist-updown",
        'EXPORT': "cw-manage-member-distributorshiplist-export"
    },
    memberGradePowerManager: {
        'ADD': "cw-manage-member-grade-power-add",
        'CHECK': "cw-manage-member-grade-power-get",
        'MODIFY': "cw-manage-member-grade-power-update",
        'DELETE': "cw-manage-member-grade-power-del"
    },
    memberGradeManager: {
        'ADDREGISTER': "cw-manage-member-grade-add-register",
        'MODIFY': "cw-manage-member-grade-update-register",
        'DELETE': "cw-manage-member-grade-del-register",
        'ADDPLUS': "cw-manage-member-grade-add-plus",
        'modifyPlus': "cw-manage-member-grade-update-plus",
        'delPlus': "cw-manage-member-grade-del-plus",
        'bind': "cw-manage-member-grade-bind-plus",
        'qrCodeRegister': "cw-manage-member-grade-scan-add",
        'lockMember': "cw-manage-member-grade-lockMember",
        'lockMemberAdd': "cw-manage-member-grade-lockMember-add",
        'lockMemberDel': "cw-manage-member-grade-lockMember-del",
    },
    memberGradePlusManager: {
        'ADD': "cw-manage-member-grade-plus-add",
        'lockMember': "cw-manage-member-grade-plus-lock",
        'setUp': "cw-manage-member-grade-plus-setUp",
        'switch': 'cw-manage-member-grade-plus-updown',

    },
    companyinformation: {
        'MODIFY': "cw-manage-install-company-update"
    },
    basemanager: {
        'ADD': "cw-manage-install-shop-add",
        'CHECK': "cw-manage-install-shop-get",
        'MODIFY': "cw-manage-install-shop-update",
        'DELETE': "cw-manage-install-shop-del"
    },
    usermanager: {
        'ADD': "cw-manage-install-account-add",
        'CHECK': "cw-manage-install-account-get",
        'MODIFY': "cw-manage-install-account-update",
        'DELETE': "cw-manage-install-account-del",
        'resetPassword': "cw-manage-install-account-reset",
        'switch': "cw-manage-install-account-changestatus",
        'EXPORT': "cw-manage-install-account-export"
    },
    rolemanager: {
        'ADD': "cw-manage-install-role-add",
        'CHECK': "cw-manage-install-role-get",
        'MODIFY': "cw-manage-install-role-update"
    },
    logmanager: {},
}
