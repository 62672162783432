import { POST } from "@net/service.js";
import $ from "@util/base";
import page from "./page"

const URL = {
    num: "gift_package_exchange_status_num",
    list: "gift_package_exchange_list",
    add: "gift_package_exchange_add",
    export: "gift_package_exchange_list_export",
    account: "choose_account",
    import: "gift_package_exchange_import",
    downloadTemplate: "gift_package_exchange_export"

};

const api = {
    num(code, succ) {
        let requestOptions = {
            path: URL.num,
            params: { giftCode: code },
            succ: (val) => {
                succ([val.total, val.create, val.used]);
            },
        };
        POST(requestOptions);
    },
    list(params, succ) {
        let paramsData = {};
        paramsData.giftCode = params.giftCode;
        if ($.isNotEmpty(params.status) && params.status != "all") {
            paramsData.giftExchangeStatus = params.status;
        }
        paramsData.page = params.page ? params.page : 1;
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
        let requestOptions = {
            path: URL.list,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    add(code, params, succ) {
        let paramsData = {};
        paramsData.giftCode = code
        paramsData.num = params.num
        if ($.isNotEmpty(params.salesman)) {
            paramsData.salesman = params.salesman;
        }
        let requestOptions = {
            path: URL.add,
            params: paramsData,
            succ: succ,
        };
        POST(requestOptions);
    },
    export (params, succ) {
        let paramsData = {};
        paramsData.giftCode = params.giftCode;
        if ($.isNotEmpty(params.status) && params.status != "all") {
            paramsData.giftExchangeStatus = params.status;
        }
        let requestOptions = {
            path: URL.export,
            params: paramsData,
            succ: succ,
        };
        POST(requestOptions);
    },
    account(succ) {
        let requestOptions = {
            path: URL.account,
            succ: val => {
                succ(val.itemList.map(e => {
                    return { label: e.name, value: e.code }
                }))
            }
        };
        POST(requestOptions);
    },
    import(params, succ) {
        let requestOptions = {
            path: URL.import,
            params: params,
            succ: succ
        }
        POST(requestOptions);
    },
    downloadTemplate(succ) {
        let requestOptions = {
            path: URL.downloadTemplate,
            succ: succ,
        };
        POST(requestOptions);
    },
}

export default api;
