const OP_KEY = {
    check: 'CHECK',
    modify: 'MODIFY',
    delete: 'DELETE',
    delete2: 'DELETE',
    copy: 'COPY',
    examine: 'examine',
    reject: 'reject',
    switch: 'switch',
    inventory: 'inventory',
    select: 'select',
    publish: 'publish',
    stop: 'stop',
    couponStop: 'couponStop',
    couponStart: 'couponStart',
    shipments: 'shipments',
    cancelOrder: 'cancelOrder',
    confirmOrder: 'confirmOrder',
    acceptOrder: 'acceptOrder',
    orderRecord: 'orderRecord',
    convertDistr: 'convertDistr',
    pass: 'pass',
    refusal: 'refusal',
    input: 'input',
    inputPrice: 'inputPrice',
    linkText: "linkText",
    cancelPublish: 'cancelPublish',
    giftCode: 'giftCode',
    grant: 'grant',
    homeShow: 'homeShow',
    unbindDistr: 'unbindDistr',
    resetPassword: 'resetPassword',
    returned: 'returned',
    appletsCode: 'appletsCode',
    acceptHotelOrder: 'acceptHotelOrder',
    depositMoney: 'depositMoney',
    freezeMoney: 'freezeMoney',
    contract: 'contract',
    registration: 'registration',
    viewDetails: 'viewDetails',
    closeGroup: 'closeGroup',
    openGroup: 'openGroup',
    occupy: 'occupy',
    cancelOccupy: 'cancelOccupy',
    confirmOccupy: 'confirmOccupy',
    confirmAfterSale: 'confirmAfterSale',
    confirmRefund: 'confirmRefund',
    confirmTakeOver: 'confirmTakeOver',
    cancelAfterSale: 'cancelAfterSale',
    changeShop: 'changeShop',
    push: 'PSUH',
    export: "export",
    docking: "docking",
    baseData: "baseData",
    grantRecord: "grantRecord",
    giveCoupon: "giveCoupon",
    bind: "bind",
    modifyPlus: "modifyPlus",
    delPlus: "delPlus",
    memberLevelChange: "memberLevelChange",
    qrCodeRegister: "qrCodeRegister",
    checkInfo: "checkInfo",
    install: "install",
    revoke: "revoke",
    userStop: "userStop",
    add2: "ADD2",
    orderInfo: "orderInfo",
    up: "up",
    down: "down",
    lockMember: "lockMember",
    lockMemberAdd:"lockMemberAdd",
    setUp:"setUp",
}
const OPERATIONS = {
    check: {key: OP_KEY.check, label: '查看'},
    modify: {key: OP_KEY.modify, label: '修改'},
    del: {key: OP_KEY.delete, label: '删除'},
    del2: {key: OP_KEY.delete2, label: '删除'},
    copy: {key: OP_KEY.copy, label: '复制'},
    examine: {key: OP_KEY.examine, label: '提交审核'},
    reject: {key: OP_KEY.reject, label: '驳回原因'},
    switch: {key: OP_KEY.switch, label: '开关'},
    baseData: {key: OP_KEY.baseData, label: '基地数据'},
    inventory: {key: OP_KEY.inventory, label: '库存变化'},
    select: {key: OP_KEY.select, label: '选择'},
    shipments: {key: OP_KEY.shipments, label: '发货'},
    cancelOrder: {key: OP_KEY.cancelOrder, label: '取消订单'},
    confirmOrder: {key: OP_KEY.confirmOrder, label: '确认订单'},
    publish: {key: OP_KEY.publish, label: '发布'},
    push: {key: OP_KEY.push, label: "发送"},
    stop: {key: OP_KEY.stop, label: '终止'},
    acceptOrder: {key: OP_KEY.acceptOrder, label: '二次已确认'},
    orderRecord: {key: OP_KEY.orderRecord, label: '订单记录'},
    convertDistr: {key: OP_KEY.convertDistr, label: '转为分销员'},
    pass: {key: OP_KEY.pass, label: '通过'},
    refusal: {key: OP_KEY.refusal, label: '驳回'},
    input: {key: OP_KEY.input, label: '排序'},
    inputPrice: {key: OP_KEY.inputPrice, label: '价格'},
    linkText: {key: OP_KEY.linkText, label: '链接'},
    cancelPublish: {key: OP_KEY.cancelPublish, label: '取消发布'},
    giftCode: {key: OP_KEY.giftCode, label: '礼包码管理'},
    grant: {key: OP_KEY.grant, label: '发放'},
    couponStop: {key: OP_KEY.couponStop, label: '停用'},
    couponStart: {key: OP_KEY.couponStart, label: '启动'},
    homeShow: {key: OP_KEY.homeShow, label: '设为首页展示'},
    unbindDistr: {key: OP_KEY.unbindDistr, label: '解除分销员'},
    resetPassword: {key: OP_KEY.resetPassword, label: '重置密码'},
    returned: {key: OP_KEY.returned, label: '已回团'},
    appletsCode: {key: OP_KEY.appletsCode, label: '小程序码'},
    acceptHotelOrder: {key: OP_KEY.acceptHotelOrder, label: '确认入住'},
    depositMoney: {key: OP_KEY.depositMoney, label: '储值余额'},
    freezeMoney: {key: OP_KEY.freezeMoney, label: '冻结余额'},
    contract: {key: OP_KEY.contract, label: '查看合同'},
    viewDetails: {key: OP_KEY.viewDetails, label: '查看详情'},
    registration: {key: OP_KEY.registration, label: '查看报名信息'},
    closeGroup: {key: OP_KEY.closeGroup, label: '关闭团期'},
    openGroup: {key: OP_KEY.openGroup, label: '开启团期'},
    occupy: {key: OP_KEY.occupy, label: '占位'},
    cancelOccupy: {key: OP_KEY.cancelOccupy, label: '取消占位'},
    confirmOccupy: {key: OP_KEY.confirmOccupy, label: '确认占位'},
    confirmAfterSale: {key: OP_KEY.confirmAfterSale, label: '确认退款'},
    confirmRefund: {key: OP_KEY.confirmRefund, label: '确认退款'},
    confirmTakeOver: {key: OP_KEY.confirmTakeOver, label: '确认收货'},
    cancelAfterSale: {key: OP_KEY.cancelAfterSale, label: '取消售后'},
    changeShop: {key: OP_KEY.changeShop, label: '变更所属基地'},
    export: {key: OP_KEY.export, label: '导出'},
    docking: {key: OP_KEY.docking, label: '对接介入'},
    grantRecord: {key: OP_KEY.grantRecord, label: '发放记录'},
    //赠送优惠券
    giveCoupon: {key: OP_KEY.giveCoupon, label: '赠送优惠券'},
    //绑定权益
    bind: {key: OP_KEY.bind, label: '绑定'},
    modifyPlus: {key: OP_KEY.modifyPlus, label: '修改'},
    delPlus: {key: OP_KEY.delPlus, label: '删除'},
    //会员等级变更
    memberLevelChange: {key: OP_KEY.memberLevelChange, label: '会员等级变更'},
    //扫码注册
    qrCodeRegister: {key: OP_KEY.qrCodeRegister, label: '扫码注册'},
    checkInfo: {key: OP_KEY.checkInfo, label: '查看变更详情'},
    install: {key: OP_KEY.install, label: '积分设置'},
    revoke: {key: OP_KEY.revoke, label: '撤回'},
    userStop: {key: OP_KEY.userStop, label: '停用'},
    add2: {key: OP_KEY.add2, label: '二次编辑上架'},
    orderInfo: {key: OP_KEY.orderInfo, label: '订单详情'},
    up: {key: OP_KEY.up, label: '上传'},
    down: {key: OP_KEY.down, label: '下载'},
    lockMember: {key: OP_KEY.lockMember, label: '查看会员'},
    lockMemberAdd: {key: OP_KEY.lockMemberAdd, label: '添加用户'},
    setUp: {key: OP_KEY.setUp, label: '权益设置'},

}

function operationsHander() {
    let operationsHanders = {}

    function receive(type, rowData) {
        let func = operationsHanders[type.key]
        if (func != undefined) {
            func(rowData)
        } else {
            console.log(type.key, type.label, "操作未处理")
        }
    }

    function install(type, func) {
        operationsHanders[type] = func
    }

    return {receive, install}
}

const COLUMN_TYPE = {
    picture: 'picture',
    switch: 'switch',
    input: 'input',
    inputPrice: 'inputPrice',
    tooltip: 'tooltip',
    depositMoney: 'depositMoney',
    freezeMoney: "freezeMoney",
    linkText: "linkText",
}
export {OP_KEY, COLUMN_TYPE, OPERATIONS, operationsHander}
